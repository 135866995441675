import React, { memo } from 'react';

import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  PopoverProps,
  Text,
  Box,
} from '@chakra-ui/react';

interface Props extends PopoverProps {
  description: string;
}

const BuildingDetailsPopover: React.FC<Props> = ({ description, ...rest }) => {
  return (
    <Popover placement="auto" {...rest}>
      <PopoverTrigger>
        <Text
          variant="link"
          cursor="pointer"
          _hover={{ textDecoration: 'underline' }}
          width="fit-content"
        >
          {description?.substr(0, 30) || '-'}
          {description?.length > 30 && '...'}
        </Text>
      </PopoverTrigger>

      <PopoverContent
        borderColor="blue.400"
        borderWidth={2}
        color="black"
        minWidth="350px"
        maxW="500px"
        width="auto"
      >
        <PopoverHeader pt={4} fontWeight="bold" border="0" textAlign="center">
          DESCRIÇÃO DA OBRA
        </PopoverHeader>

        <PopoverArrow />
        <PopoverCloseButton />

        <PopoverBody maxHeight="400px" overflowY="auto">
          {description ? (
            <Box
              whiteSpace="pre-wrap"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          ) : (
            '-'
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default memo(BuildingDetailsPopover);
