import React, { Fragment, useCallback, useEffect, useState } from 'react';

import { api } from 'services/api';
import { toast } from 'shared/toast';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  ModalFooter,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Flex,
  Text,
  Grid,
  Textarea,
  HStack,
  Box,
  keyframes,
} from '@chakra-ui/react';

import { ModalRootProps } from 'components/Modal/Root';
import OwnSelect from 'components/OwnSelect';

import useModal from 'hooks/useModal';

import { ParametricBudget } from 'types/budget/parametric-budget';

type FormErrors = {
  [key: string]: string | boolean;
  type: 'invalid' | 'required';
};

type Action = ParametricBudget['flow_status']['actions'][number];

interface ModalManageParametricFlowProps extends ModalRootProps {
  data: {
    budget: ParametricBudget;
  };
}

const spin = keyframes`
  0% {opacity: 0; scale: 0;}
  50% {opacity: 0.3;  scale: 1;}
  100% {opacity: 0;  scale: 0;}
`;

type Option = {
  label: string;
  description: string;
  color: string;
};

const formatOptionLabel = ({ label, color }: Option): JSX.Element => {
  const spinAnimation = `${spin} infinite 2s ease`;

  return (
    <Flex direction="row" alignItems="center">
      <Box
        width={3}
        height={3}
        borderRadius="50%"
        bg={color}
        position="relative"
        boxShadow="base"
        flexShrink={0}
        _before={{
          content: '""',
          position: 'absolute',
          top: -1,
          left: -1,
          right: -1,
          bottom: -1,
          bg: color,
          opacity: 0.3,
          borderRadius: '50%',
          animation: spinAnimation,
        }}
        marginRight={2}
      />
      <b>{label}</b>
    </Flex>
  );
};

const ModalManageParametricFlow: React.FC<ModalManageParametricFlowProps> = ({
  onConfirm,
  handleClose,
  data,
  ...restProps
}) => {
  const { budget } = data;

  const { openModal } = useModal();

  const [selectedAction, setSelectedAction] = useState<Action>();

  const [observation, setObservation] = useState<string>();

  const [actions, setData] = useState<Action[]>([]);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<FormErrors | undefined>();

  const getData = useCallback(async () => {
    setLoading(true);
    setData([]);

    try {
      const response = await api.get(
        `flow/status/${budget.flow_status.id}/action`,
      );

      const actionList = response.data;

      setData(actionList.data);
    } catch (err) {
      toast({
        description:
          err.response?.data?.message || 'Houve um erro ao carregar as ações!',
        status: 'error',
      });
      setData([]);
    } finally {
      setLoading(false);
    }
  }, [budget]);

  useEffect(() => {
    getData();
  }, [getData]);

  const actionsToSelect = actions.map((item) => ({
    value: item.id,
    label: item.name,
    description: item.description,
    color: item.color,
  }));

  type Organization = ParametricBudget['organization'];
  const getOrganizationName = (organization: Organization): JSX.Element => {
    if (organization.document_type === 'cnpj') {
      return (
        <>
          {organization.fantasy_name}
          <br />
          <small>{organization.corporate_name}</small>
        </>
      );
    }

    return <>{organization.name}</>;
  };

  const handleConfirm = async (): Promise<void> => {
    const formErrors = {} as FormErrors;

    if (!selectedAction) {
      Object.assign(formErrors, { selectedAction: true, type: 'required' });
    }

    if (!observation) {
      Object.assign(formErrors, { observation: true, type: 'required' });
    }

    setErrors(formErrors);

    if (Object.keys(formErrors).length) {
      return;
    }

    try {
      setLoading(true);

      await api.post(`/flow/action/${selectedAction?.id}`, {
        object_type: 'parametric_budget',
        object_id: budget.id,
        flow_key: 'use_flow',
        type: 'manual',
        message: observation,
      });

      toast({
        description: `Status atualizado com sucesso!`,
        status: 'success',
      });

      if (onConfirm) onConfirm();
      handleClose();
    } catch (err) {
      toast({
        description:
          err.response?.data?.message || 'Houve um erro ao performar a ação!',
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal {...restProps} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Atualizar status do orçamento</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Grid
            w="100%"
            templateColumns="repeat(auto-fit, minmax(300px, 1fr))"
            columnGap={2}
            rowGap={4}
            paddingY={4}
            borderTop="1px solid rgba(0,0,0,0.1)"
            borderBottom="1px solid rgba(0,0,0,0.1)"
          >
            <Flex direction="row" gap={1}>
              <Text fontWeight="bold">Descrição: </Text>
              <Text
                noOfLines={1}
                textOverflow="ellipsis"
                title={budget.build_description}
              >
                {budget.build_description || 'Não especificado'}
              </Text>
            </Flex>

            <Flex direction="row" gap={1}>
              <Text fontWeight="bold">Status atual: </Text>
              <Text noOfLines={1} textOverflow="ellipsis">
                {budget.flow_status?.name || 'Não especificado'}
              </Text>
            </Flex>

            <Flex direction="row" gap={1}>
              <Text fontWeight="bold">Organização</Text>
              <Text noOfLines={1} textOverflow="ellipsis">
                {getOrganizationName(budget.organization)}
              </Text>
            </Flex>
          </Grid>

          <FormControl marginTop="4" isInvalid={!!errors?.selectedAction}>
            <FormLabel>Ação</FormLabel>
            <OwnSelect
              placeholder="Selecione"
              options={actionsToSelect}
              value={
                selectedAction
                  ? {
                      value: selectedAction.id,
                      label: selectedAction.name,
                      description: selectedAction.description,
                      color: selectedAction.color,
                    }
                  : null
              }
              isInvalid={!!errors?.selectedAction}
              isLoading={loading}
              isDisabled={loading || !actions.length}
              isClearable
              onChange={(selected) => {
                const value = actions.find(
                  (item) => item.id === Number(selected?.value),
                );
                setSelectedAction(value);
              }}
              formatOptionLabel={formatOptionLabel}
            />
            <FormErrorMessage>Ação é obrigatória</FormErrorMessage>
          </FormControl>

          <FormControl marginTop="4" isInvalid={!!errors?.observation}>
            <FormLabel>Justificativa</FormLabel>
            <Textarea
              resize="none"
              onChange={(e) => setObservation(e.target.value)}
            />
            <FormErrorMessage>Justificativa é obrigatória</FormErrorMessage>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <HStack spacing={2}>
            <Button
              isLoading={loading}
              isDisabled={loading}
              onClick={() =>
                openModal('parametricBudgetHistory', {
                  mode: 'lookup',
                  data: {
                    budget,
                  },
                })
              }
            >
              Ver histórico
            </Button>

            <Button
              isLoading={loading}
              isDisabled={loading}
              colorScheme="green"
              onClick={handleConfirm}
            >
              Salvar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalManageParametricFlow;
