import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useMemo,
} from 'react';

import { api } from 'services/api';
import { toast } from 'shared/toast';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  ModalFooter,
} from '@chakra-ui/react';

import { ModalRootProps } from 'components/Modal/Root';
import OwnSelect from 'components/OwnSelect';

import AuthContext from 'contexts/AuthContext';

import { Base } from 'types/base';

const ModalBaseSelect: React.FC<ModalRootProps> = ({
  onConfirm,
  handleClose,
  ...restProps
}) => {
  const { user } = useContext(AuthContext);
  const [bases, setBases] = useState<Base[]>([]);
  const [loading, setLoading] = useState(false);

  const [selectedBase, setSelectedBase] = useState<number>();

  const getBases = useCallback(async () => {
    setLoading(true);

    try {
      const { data: basesLoaded } = await api.get('/base/list', {
        params: {
          'filter[with_plan]': `.`,
          'filter[organization_id]': user.managed_organization.organization.id,
          sort: 'description',
        },
      });
      setBases(basesLoaded);

      if (basesLoaded.length === 1) {
        const [base] = basesLoaded;
        setSelectedBase(base.id);
      }
    } catch (err) {
      toast({
        description:
          err.response?.data?.message || 'Houve um erro ao carregar as bases!',
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const basesToSelect = useMemo(() => {
    return bases.map((base) => ({
      value: String(base.id),
      label: base.description,
    }));
  }, [bases]);

  useEffect(() => {
    getBases();
  }, [getBases]);

  type Selected = { value: string } | null;
  const handleBaseChange = (selected: Selected): void => {
    const value = selected ? Number(selected.value) : undefined;
    setSelectedBase(value);
  };

  const handleConfirm = (): void => {
    const base = bases.find((item) => item.id === selectedBase);

    if (!base) {
      toast({
        description: 'Selecione uma base!',
        status: 'warning',
      });

      return;
    }

    if (onConfirm) onConfirm(base);
    handleClose();
  };

  return (
    <Modal {...restProps}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Selecionar base de dados</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <OwnSelect
            placeholder="Selecione"
            options={basesToSelect}
            value={basesToSelect.filter(
              (s) => Number(s.value) === selectedBase,
            )}
            isLoading={loading}
            isDisabled={loading || basesToSelect.length === 0}
            onChange={handleBaseChange}
          />
        </ModalBody>

        <ModalFooter>
          <Button
            isDisabled={loading}
            colorScheme="green"
            onClick={handleConfirm}
          >
            Selecionar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalBaseSelect;
