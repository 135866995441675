import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { InfoCircleFill as InfoCircleFillIcon } from '@styled-icons/bootstrap/InfoCircleFill';
import { api } from 'services/api';
import { toast } from 'shared/toast';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  ModalFooter,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  SimpleGrid,
  Checkbox,
  Select,
  Tooltip,
  Icon,
} from '@chakra-ui/react';

import { ModalRootProps } from 'components/Modal/Root';
import OwnSelect, { SelectedValues } from 'components/OwnSelect';

import { State } from 'types/address';
import { Locale } from 'types/base';

type FormErrors = {
  [key: string]: string | boolean;
  type: 'invalid' | 'required';
};

interface ModalBaseAddProps extends ModalRootProps {
  data: {
    base_id?: number;
    description?: string;
    use_social_law?: boolean;
  };
}

type PriceType = 'taxed,untaxed' | 'default';

const ModalBaseAdd: React.FC<ModalBaseAddProps> = ({
  onConfirm,
  handleClose,
  data = {},
  mode,
  ...restProps
}) => {
  const [description, setDescription] = useState<string>(
    data.description || '',
  );

  const [modelType] = useState('sinapi');

  const descriptionRef = React.useRef<HTMLInputElement>(null);
  const [useSocialLaw, setUseSocialLaw] = useState(
    data.use_social_law || false,
  );

  const [isAllStates, setIsAllStates] = useState(false);
  const [stateSelected, setStateSelected] = useState<number[]>([]);
  const [localeList, setLocaleList] = useState<Locale[]>([]);
  const [stateList, setStateList] = useState<State[]>([]);

  const [selectedPriceType, setSelectedPriceType] =
    useState<PriceType>('default');

  const [loading, setLoading] = useState(false);
  const [isFindingStates, setIsFindingStates] = useState(false);
  const [isFindingLocales, setIsFindingLocales] = useState(false);
  const [, setIsFindingPriceTypes] = useState(false);

  const [errors, setErrors] = useState<FormErrors | undefined>();

  useEffect(() => {
    if (descriptionRef.current) {
      descriptionRef.current.focus();
    }
  }, []);

  const statesToSelect = useMemo(
    () =>
      stateList.map((state) => ({
        value: state.id,
        label: state.name,
      })),
    [stateList],
  );

  const getStates = useCallback(async () => {
    setIsFindingStates(true);
    try {
      const { data: responseStates } = await api.get(`/state`);
      const states = responseStates.data;
      setStateList(states);
    } catch (err) {
      toast({
        description:
          err.response?.data?.message ||
          'Houve um erro ao tentar carregar a lista de estados.',
        status: 'error',
      });
    } finally {
      setIsFindingStates(false);
    }
  }, []);

  useEffect(() => {
    getStates();
  }, [getStates]);

  const getLocales = useCallback(async () => {
    setIsFindingLocales(true);
    try {
      const { data: responseLocales } = await api.get('/locale', {
        params: {
          'filter[status]': 'active',
          'filter[base_id]': data.base_id,
          sort: 'description',
        },
      });
      const locales = responseLocales.data;
      setLocaleList(locales);
    } catch (err) {
      toast({
        description:
          err.response?.data?.message ||
          'Houve um erro ao tentar carregar a lista de estados.',
        status: 'error',
      });
    } finally {
      setIsFindingLocales(false);
    }
  }, [data]);

  useEffect(() => {
    if (data.base_id) {
      getLocales();
    }
  }, [getLocales, data]);

  const getPriceTypes = useCallback(async () => {
    setIsFindingPriceTypes(true);
    try {
      const { data: responsePriceTypes } = await api.get('/price/type', {
        params: {
          'filter[status]': 'active',
          'filter[base_id]': data.base_id,
          sort: 'description',
        },
      });
      const locales = responsePriceTypes.data;
      if (locales.length === 2) {
        setSelectedPriceType('taxed,untaxed');
      } else {
        setSelectedPriceType('default');
      }
    } catch (err) {
      toast({
        description:
          err.response?.data?.message ||
          'Houve um erro ao tentar carregar a lista de estados.',
        status: 'error',
      });
    } finally {
      setIsFindingPriceTypes(false);
    }
  }, [data]);

  useEffect(() => {
    if (data.base_id) {
      getPriceTypes();
    }
  }, [getPriceTypes, data]);

  useEffect(() => {
    if (localeList.length > 0 && stateList.length > 0) {
      const localesIds = stateList
        .filter((state) =>
          localeList.some((locale) => locale.key === state.abbreviation),
        )
        .map((state) => state.id);
      setStateSelected(localesIds);
    }
  }, [localeList, stateList]);

  const getStatesNameById = useCallback(
    (stateId: number[]) => {
      const states = stateList.filter((state) => stateId.includes(state.id));
      return states.map((state) => ({
        key: state.abbreviation,
        description: state.name,
      }));
    },
    [stateList],
  );

  useEffect(() => {
    if (stateSelected.length === statesToSelect.length) {
      setIsAllStates(true);
    } else {
      setIsAllStates(false);
    }
  }, [stateSelected, statesToSelect]);

  const handleConfirm = async (): Promise<void> => {
    const formErrors: FormErrors = {} as FormErrors;

    if (stateSelected.length === 0) {
      Object.assign(formErrors, { states: true, type: 'required' });
    }

    if (!description) {
      Object.assign(formErrors, { description: true, type: 'required' });
    }

    setErrors(formErrors);
    if (Object.keys(formErrors).length) {
      return;
    }

    try {
      setLoading(true);

      if (mode === 'add') {
        const { data: newBase } = await api.post(`/base`, {
          key: 'own',
          description,
          model_type: modelType,
          use_social_law: useSocialLaw,
          locales: getStatesNameById(stateSelected),
          price_types:
            selectedPriceType === 'default'
              ? [{ key: 'default', description: 'Padrão' }]
              : [
                  {
                    key: 'taxed',
                    description: 'Não Desonerado',
                  },
                  {
                    key: 'untaxed',
                    description: 'Desonerado',
                  },
                ],
        });

        const newBaseId = newBase.data.id;

        await api.post(`/version`, {
          base_id: newBaseId,
          description: 'Ref. Padrão',
        });
      } else if (mode === 'edit') {
        await api.put(`/base/${data.base_id}`, {
          key: 'own',
          description,
          model_type: modelType,
          use_social_law: useSocialLaw,
          locales: getStatesNameById(stateSelected),
        });
      }

      if (onConfirm) onConfirm();

      toast({
        description:
          mode === 'add'
            ? 'Base adicionada com sucesso!'
            : 'Base editada com sucesso!',
        status: 'success',
      });

      handleClose();
    } catch (err) {
      toast({
        description:
          err.response?.data?.message ||
          (mode === 'add'
            ? 'Houve um erro ao adicionar a base!'
            : 'Houve um erro ao editar a base!'),
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal {...restProps} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {mode === 'add' ? 'Adicionar base' : 'Editar base'}
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
            <FormControl marginTop="4" isInvalid={!!errors?.description}>
              <FormLabel>Descrição</FormLabel>

              <Input
                ref={descriptionRef}
                type="text"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && handleConfirm()}
              />

              <FormErrorMessage>
                {!!errors?.description && 'Descrição é obrigatória'}
              </FormErrorMessage>
            </FormControl>

            <FormControl marginTop="4">
              <FormLabel>Modelo</FormLabel>

              <Input
                type="text"
                value={modelType.toLocaleUpperCase()}
                isReadOnly
              />

              <FormErrorMessage>
                {!!errors?.modelType && 'Modelo é obrigatório'}
              </FormErrorMessage>
            </FormControl>
          </SimpleGrid>
          <FormControl isInvalid={!!errors?.states} mt={6}>
            <FormLabel>
              Estado{' '}
              <Checkbox
                mt={1}
                ml={5}
                mr={1}
                isChecked={isAllStates}
                onChange={(e) => {
                  setIsAllStates(e.target.checked);
                  if (e.target.checked) {
                    setStateSelected(
                      statesToSelect.map((state) => state.value),
                    );
                  }
                }}
                size="sm"
              />{' '}
              Todos os estados
            </FormLabel>

            <OwnSelect
              placeholder="Selecione"
              options={statesToSelect}
              isMulti
              value={statesToSelect.filter((s) =>
                stateSelected.includes(s.value),
              )}
              isLoading={isFindingStates || isFindingLocales}
              isDisabled={
                isFindingStates ||
                isFindingLocales ||
                statesToSelect.length === 0
              }
              isClearable
              onChange={(selected: SelectedValues) => {
                const value = selected.map((s) => Number(s.value));
                setStateSelected(value);
              }}
            />
            <FormErrorMessage>Estado é obrigatório</FormErrorMessage>
          </FormControl>
          <FormControl mt={6}>
            <FormLabel>Tipo de preço</FormLabel>

            <Select
              value={selectedPriceType}
              onChange={(e) =>
                setSelectedPriceType(e.target.value as PriceType)
              }
              isDisabled={mode === 'edit'}
              _disabled={{
                background: 'hsl(0, 0%, 95%)',
                border: '1px solid hsl(0, 0%, 90%)',
              }}
            >
              <option value="variable">Padrão</option>
              <option value="taxed,untaxed">Desonerado / Não desonerado</option>
            </Select>
            <FormErrorMessage>Estado é obrigatório</FormErrorMessage>
          </FormControl>

          <Checkbox
            mt={5}
            mr={1}
            isChecked={useSocialLaw}
            onChange={(e) => setUseSocialLaw(e.target.checked)}
          >
            Campo de leis sociais (%){' '}
            <Tooltip
              hasArrow
              label="Marque esta opção caso deseja modificar o % (percentual) das Leis Sociais na configuração do Orçamento."
            >
              <Icon as={InfoCircleFillIcon} className="icon" color="blue.300" />
            </Tooltip>
          </Checkbox>
        </ModalBody>

        <ModalFooter>
          <Button
            isLoading={loading}
            isDisabled={loading}
            colorScheme="green"
            onClick={handleConfirm}
          >
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalBaseAdd;
