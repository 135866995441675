import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useContext,
} from 'react';
import { useDropzone } from 'react-dropzone';

import { InfoCircleFill as InfoCircleFillIcon } from '@styled-icons/bootstrap/InfoCircleFill';
import { motion, AnimatePresence } from 'framer-motion';
import { api } from 'services/api';
import { toast } from 'shared/toast';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  ModalFooter,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Tooltip,
  Icon,
  Box,
  Progress,
  Text,
} from '@chakra-ui/react';

import { ModalRootProps } from 'components/Modal/Root';
import OwnSelect from 'components/OwnSelect';

import AuthContext from 'contexts/AuthContext';

import { Base, Version } from 'types/base';

type FormErrors = {
  [key: string]: string | boolean;
  type: 'invalid' | 'required';
};

interface ModalImportProps extends ModalRootProps {
  data: {
    type: 'own_input' | 'own_composition';
  };
}

const baseStyle: React.CSSProperties = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 4,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer',
  textAlign: 'center',
  fontSize: '14px',
};

const focusedStyle = {
  borderColor: '#2196f3',
};

const selectedStyle = {
  borderColor: '#2196f3',
  color: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
  color: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
  color: '#ff1744',
};

const MotionBox = motion(Box);

const ModalImport: React.FC<ModalImportProps> = ({
  data: { type = 'own_input' },
  onConfirm,
  handleClose,
  ...restProps
}) => {
  const { user } = useContext(AuthContext);

  const [bases, setBases] = useState<Base[]>([]);
  const [selectedBase, setSelectedBase] = useState<number>();
  const [isLoadingBases, setIsLoadingBases] = useState(false);

  const [baseVersions, setBaseVersions] = useState<Version[]>([]);
  const [selectedBaseVersion, setSelectedBaseVersion] = useState<number>();
  const [isLoadingBaseVersions, setIsLoadingBaseVersions] = useState(false);

  const [progress, setProgress] = useState(0);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<FormErrors>({} as FormErrors);

  const [file, setFile] = React.useState<File | null>(null);
  const [headerLine, setHeaderLine] = React.useState<number | undefined>();

  const getBases = useCallback(async () => {
    setIsLoadingBases(true);

    try {
      const { data: response } = await api.get('/base', {
        params: {
          sort: `description`,
          'filter[organization_id]': user.managed_organization.organization.id,
        },
      });
      const basesLoaded = response.data;
      setBases(basesLoaded);

      if (basesLoaded.length === 1) {
        const [base] = basesLoaded;
        setSelectedBase(base.id);
        setErrors({ ...errors, base: false });
      }
    } catch (err) {
      toast({
        description:
          err.response?.data?.message || 'Houve um erro ao carregar as bases!',
        status: 'error',
      });
    } finally {
      setIsLoadingBases(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const basesToSelect = useMemo(() => {
    return bases.map((base) => ({
      value: String(base.id),
      label: base.description,
    }));
  }, [bases]);

  useEffect(() => {
    getBases();
  }, [getBases]);

  const getBaseVersions = useCallback(async (baseId?: number) => {
    if (!baseId) {
      setBaseVersions([]);
      return;
    }

    try {
      setIsLoadingBaseVersions(true);
      const { data: response } = await api.get('version', {
        params: {
          'filter[base_id]': baseId,
        },
      });
      const baseVersionsLoaded = response.data;
      setBaseVersions(baseVersionsLoaded);

      if (baseVersionsLoaded.length === 1) {
        const [baseVersion] = baseVersionsLoaded;
        setSelectedBaseVersion(baseVersion.id);
        setErrors({ ...errors, baseVersion: false });
      }
    } catch (err) {
      toast({
        description:
          err.response?.data?.message ||
          'Houve um erro ao carregar as versões da base!',
        status: 'error',
      });
    } finally {
      setIsLoadingBaseVersions(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const baseVersionsToSelect = useMemo(() => {
    return baseVersions.map((base) => ({
      value: String(base.id),
      label: base.description,
    }));
  }, [baseVersions]);

  useEffect(() => {
    getBaseVersions(selectedBase);
  }, [getBaseVersions, selectedBase]);

  type Selected = { value: string } | null;
  const handleBaseChange = (selected: Selected): void => {
    const value = selected ? Number(selected.value) : undefined;

    setSelectedBase(value);
  };

  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
  }, []);

  const accept = {
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
      '.xlsx',
      '.xls',
    ],
    'text/csv': ['.csv'],
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept,
    onDrop,
  });

  const handleConfirm = async (): Promise<void> => {
    const formErrors: FormErrors = {} as FormErrors;

    if (!headerLine) {
      formErrors.header = 'Linha do cabeçalho é obrigatório';
    }

    if (!file) {
      formErrors.file = 'Arquivo é obrigatório';
    }

    if (!selectedBase) {
      formErrors.base = 'Base é obrigatório';
    }

    if (!selectedBaseVersion) {
      formErrors.baseVersion = 'Versão da base é obrigatório';
    }

    setErrors(formErrors);
    if (Object.keys(formErrors).length || !file) {
      return;
    }

    try {
      setLoading(true);

      const formData = new FormData();
      formData.append('type', type);
      formData.append('header_row', String(headerLine));
      formData.append('description', String(file.name));
      formData.append('version_id', String(selectedBaseVersion));
      formData.append('file', file);

      const {
        data: {
          id: importId,
          mapped_header: mappedHeaders,
          header_row: headerRow,
          version,
          file: importedFile,
        },
      } = await api
        .post(`/import`, formData, {
          onUploadProgress: (progressEvent) => {
            const uploadPercentage = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total,
            );

            if (uploadPercentage < 100) {
              setProgress(uploadPercentage);
            }
          },
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          setProgress(100);

          return response.data;
        });

      if (onConfirm)
        onConfirm({
          id: importId,
          mappedHeaders,
          headerRow,
          baseId: selectedBase,
          baseName: version?.base?.description || 'Não definido',
          versionName: version?.description || 'Não definido',
          fileName: importedFile.name,
        });

      handleClose();
    } catch (err) {
      setProgress(0);
      toast({
        description:
          err.response?.data?.message ||
          'Houve um erro inesperado ao importar o arquivo.',
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(file ? selectedStyle : {}),
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
      ...(errors.file ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject, file, errors],
  );

  useEffect(() => {
    if (file && errors.file) {
      const newErrors = errors;
      delete newErrors.file;
      setErrors(newErrors);
    }
  }, [file, errors]);

  const extensions = Object.values(accept).flat().join(', ');

  const dropMessage = useMemo(() => {
    if (file) {
      return `Selecionado: ${file.name}`;
    }

    if (isDragAccept) {
      return 'Tudo certo! Pode soltar os arquivos aqui.';
    }

    if (isDragReject) {
      return `Tipo de arquivo não suportado. Tipos suportados: ${extensions}.`;
    }

    if (isDragActive) {
      return 'Solte os arquivos aqui...';
    }

    return 'Arraste ou clique para selecionar o arquivo do orçamento.';
  }, [file, extensions, isDragAccept, isDragReject, isDragActive]);

  return (
    <Modal size="2xl" {...restProps}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Importar Excel / CSV</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <FormControl isInvalid={!!errors.base}>
            <FormLabel>Base de dados</FormLabel>
            <OwnSelect
              placeholder="Selecione"
              isInvalid={!!errors.base}
              options={basesToSelect}
              value={basesToSelect.filter(
                (s) => Number(s.value) === selectedBase,
              )}
              isLoading={isLoadingBases}
              isDisabled={isLoadingBases || basesToSelect.length === 0}
              // isClearable
              onChange={handleBaseChange}
            />
          </FormControl>

          <FormControl isInvalid={!!errors.baseVersion} mt={4}>
            <FormLabel>Referência</FormLabel>

            <OwnSelect
              placeholder="Selecione"
              isInvalid={!!errors.baseVersion}
              options={baseVersionsToSelect}
              value={baseVersionsToSelect.filter(
                (s) => Number(s.value) === selectedBaseVersion,
              )}
              isLoading={isLoadingBases || isLoadingBaseVersions}
              isDisabled={
                isLoadingBaseVersions || baseVersionsToSelect.length === 0
              }
              // isClearable
              onChange={(selected) => {
                const value = selected ? Number(selected.value) : undefined;

                setSelectedBaseVersion(value);
              }}
            />

            <FormErrorMessage>
              {errors.version_id && 'Referência é obrigatória.'}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors?.file} mt={4}>
            <FormLabel>Arquivo</FormLabel>

            <div {...getRootProps({ style })}>
              <input {...getInputProps()} />

              <p>{dropMessage}</p>
            </div>

            <FormErrorMessage>
              {!!errors?.file && 'Arquivo é obrigatório'}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors?.header} mt={4}>
            <FormLabel>
              Linha do cabeçalho{' '}
              <Tooltip
                ml={1}
                hasArrow
                label="Informe a em qual linha se encontra cabeçalho do orçamento no arquivo."
              >
                <Icon as={InfoCircleFillIcon} className="icon" />
              </Tooltip>
            </FormLabel>

            <Input
              type="number"
              value={headerLine}
              onChange={(e) => {
                if (e.target.value === '') {
                  setHeaderLine(undefined);
                  return;
                }

                const value = Number(e.target.value);

                if (value < 1) {
                  return;
                }

                setHeaderLine(value);
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleConfirm();
                }
              }}
            />

            <FormErrorMessage>
              {!!errors?.header && 'Linha do cabeçalho é obrigatória'}
            </FormErrorMessage>
          </FormControl>

          <AnimatePresence>
            {progress > 0 && progress <= 100 ? (
              <MotionBox
                initial={{
                  opacity: 0,
                  scale: 0.4,
                }}
                animate={{
                  opacity: 1,
                  scale: 1,
                  transition: {
                    duration: 0.25,
                  },
                }}
                exit={{
                  opacity: 0,
                  top: -30,
                }}
                transition="all 500ms ease"
                height="auto"
              >
                <Text mt={8}>
                  Enviando arquivo...
                  <Progress
                    mt={2}
                    value={progress}
                    hasStripe
                    isAnimated
                    colorScheme="blue"
                    borderRadius="8"
                  />
                </Text>
              </MotionBox>
            ) : null}
          </AnimatePresence>
        </ModalBody>

        <ModalFooter>
          <Button
            isLoading={loading}
            isDisabled={loading}
            colorScheme="green"
            onClick={handleConfirm}
          >
            Próximo
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalImport;
