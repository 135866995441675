import React, { useState, useEffect, useCallback } from 'react';

import { Check as CheckIcon } from '@styled-icons/boxicons-regular/Check';
import { api } from 'services/api';
import { formatDate } from 'shared/date';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  SimpleGrid,
  HStack,
  Flex,
  Input,
  Select,
  Button,
  Icon,
  Text,
} from '@chakra-ui/react';

import AppTable from 'components/AppTable';
import { ModalRootProps } from 'components/Modal/Root';
import PaginationWrapper from 'components/Pagination';

import useDebouncedState from 'hooks/useDebouncedState';
import useThrottledState from 'hooks/useThrottledState';

import { ParametricBudget } from 'types/budget/parametric-budget';
import { Pagination, ServicePagination } from 'types/pagination';

import BuildingDescriptionPopover from './BuildingDescriptionPopover';
import { typeMap, patternMap } from './shared';

const ModalParametricBudgetLookup: React.FC<ModalRootProps> = ({
  onConfirm,
  handleClose,
  ...restProps
}) => {
  const [data, setData] = useState<ParametricBudget[]>([]);
  const [loading, setLoading] = useState(false);

  const [keyword, setKeyword] = useDebouncedState<string>('');
  const [pagination, setPagination] = useThrottledState<Pagination>(
    (() => {
      return {
        per_page: 5,
        page: 1,
      };
    })(),
    1000,
  );

  const [servicePagination, setServicePagination] = useState<ServicePagination>(
    { last_page: 1 },
  );

  const getData = useCallback(async () => {
    setLoading(true);
    setData([]);

    try {
      const response = await api.get('/parametric/budget', {
        params: {
          'filter[keyword]': keyword,
          page: pagination.page,
          per_page: pagination.per_page,
          sort: '-created_at',
        },
      });

      const parametricBudgets = response.data;

      const newPagination = {
        last_page: parametricBudgets.meta.last_page,
      };

      setData(parametricBudgets.data);
      setServicePagination(newPagination);
    } catch (err) {
      setData([]);
      setServicePagination({ last_page: 1 });
    } finally {
      setLoading(false);
    }
  }, [keyword, pagination]);

  useEffect(() => {
    getData();
  }, [getData]);

  type Organization = ParametricBudget['organization'];
  const getOrganizationName = (organization: Organization): JSX.Element => {
    if (organization.document_type === 'cnpj') {
      return (
        <small>
          {organization.fantasy_name
            ? organization.fantasy_name
            : organization.corporate_name}
        </small>
      );
    }

    return <>{organization.name}</>;
  };

  const getBuildTypeDescription = (buildType: string): string => {
    return typeMap[buildType as keyof typeof typeMap] || 'Não definido';
  };

  const getPatternDescription = (
    buildType: string,
    pattern: string | undefined,
  ): string => {
    let key = buildType;
    if (pattern) {
      key = `${buildType}:${pattern}`;
    }
    return patternMap[key as keyof typeof patternMap] || 'Não definido';
  };

  const handleConfirm = (parametricBudget: ParametricBudget): void => {
    if (onConfirm) onConfirm(parametricBudget);
    handleClose();
  };

  useEffect(() => {
    if (pagination.page > servicePagination?.last_page) {
      setPagination((oldPagination) => {
        if (oldPagination.page > 1) {
          return {
            ...oldPagination,
            page: 1,
          };
        }

        return oldPagination;
      });
    }
  }, [pagination.page, servicePagination, setPagination]);

  return (
    <Modal {...restProps} size="6xl" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Selecionar orçamento paramétrico</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <SimpleGrid columns={{ base: 1, md: 2 }} mt={6}>
            <HStack mb={3}>
              <Text fontSize="smaller">Por página:</Text>
              <Select
                width="auto"
                defaultValue={pagination.per_page}
                onChange={(e) => {
                  setPagination({
                    ...pagination,
                    per_page: Number(e.target.value),
                  });
                }}
              >
                {[5, 10, 20, 50, 100].map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </Select>
            </HStack>

            <Flex
              flexWrap={{ base: 'wrap', md: 'nowrap' }}
              mb={3}
              gridGap={{ base: 0, md: 3 }}
            >
              <Input
                type="text"
                placeholder="Pesquisar"
                defaultValue={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                mb={{ base: 3, md: 0 }}
              />
            </Flex>
          </SimpleGrid>

          <AppTable
            cols={[
              { field: 'created_at', description: 'Criação' },
              { field: 'build_description', description: 'Descrição' },
              { field: 'build_type', description: 'Tipo' },
              { field: 'total_cost', description: 'Total', isNumeric: true },
              { field: 'actions', description: 'Ações' },
            ]}
            mapping={(item) => ({
              id: item.id,
              created_at: (
                <>
                  <Text mb={1}>{formatDate(item.created_at)}</Text>
                  {!!item.organization && (
                    <Text
                      as="span"
                      fontWeight="bold"
                      fontSize="smaller"
                      title="Organização"
                      maxWidth={{ base: '100%', md: '150px', lg: '250px' }}
                      whiteSpace="pre-wrap"
                    >
                      {getOrganizationName(item.organization)}
                    </Text>
                  )}
                </>
              ),
              build_description: (
                <BuildingDescriptionPopover
                  description={item.build_description}
                />
              ),
              build_type: (
                <>
                  <Text
                    mb={1}
                    maxWidth={{ base: '100%', md: '150px', lg: '250px' }}
                    whiteSpace="pre-wrap"
                    fontWeight="bold"
                    display="flex"
                    alignItems="center"
                  >
                    {getBuildTypeDescription(item.build_type)}
                  </Text>

                  <Text
                    mb={1}
                    maxWidth={{ base: '100%', md: '150px', lg: '250px' }}
                    whiteSpace="pre-wrap"
                    fontSize="smaller"
                  >
                    {getPatternDescription(item.build_type, item.build_pattern)}
                    {item.state && ` - ${item.state.name}`}
                  </Text>
                </>
              ),
              total_cost: Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }).format(item.total_cost),
              actions: (
                <Button
                  colorScheme="green"
                  size="sm"
                  onClick={() => handleConfirm(item)}
                  leftIcon={<Icon as={CheckIcon} />}
                >
                  Selecionar
                </Button>
              ),
            })}
            data={data}
            loading={loading}
            onUpdateSort={() => {
              //
            }}
          />

          <PaginationWrapper
            lastPage={servicePagination.last_page}
            onPaginate={(selectedPage) => {
              setPagination({ ...pagination, page: selectedPage });
            }}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalParametricBudgetLookup;
