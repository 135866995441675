import React, { useState, useCallback, useEffect } from 'react';

import { api } from 'services/api';
import { toast } from 'shared/toast';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';

import { ModalRootProps } from 'components/Modal/Root';

import { ServiceClient, PreparedClient } from 'types/client';

import Form from './Form';

type Props = ModalRootProps & {
  data?: {
    client_id?: number;
  };
};

const ModalClientLookup: React.FC<Props> = ({ data, ...rest }) => {
  const [client, setClient] = useState<ServiceClient>({} as ServiceClient);

  const getClient = useCallback(async (): Promise<void> => {
    try {
      if (data?.client_id) {
        const response = await api.get(`/client/${data.client_id}`);

        const clientLoaded = response.data;

        setClient({
          ...clientLoaded.data,
        });
      }
    } catch (err) {
      toast({
        description:
          err.response?.data?.message ||
          'Houve um erro ao tentar carregar o cliente',
        status: 'error',
      });
    }
  }, [setClient, data]);

  useEffect(() => {
    getClient();
  }, [getClient]);

  const prepareClient = (value: ServiceClient): PreparedClient => ({
    ...value,
    type_person: value.type_person || 'physical',
    document: value.document,
    cep: value.zip_code || '',
    state_name: value.city_id?.state.name,
    city_name: value.city_id?.name,
  });

  return (
    <Modal {...rest} size="6xl" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Visualizar cliente</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Form initialData={prepareClient(client)} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalClientLookup;
