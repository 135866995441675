import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useExpanded, useTable } from 'react-table';

import { DownArrow as DownArrowIcon } from '@styled-icons/boxicons-solid/DownArrow';
import { RightArrow as RightArrowIcon } from '@styled-icons/boxicons-solid/RightArrow';
import { TrafficCone as TrafficConeIcon } from '@styled-icons/entypo/TrafficCone';
import { StyledIcon } from '@styled-icons/styled-icon';
import { MdiReactIconComponentType } from 'mdi-react';
import BricksIcon from 'mdi-react/BricksIcon';
import GroupIcon from 'mdi-react/GroupIcon';
import InfoIcon from 'mdi-react/InfoCircleOutlineIcon';
import ShovelIcon from 'mdi-react/ShovelIcon';
import { api } from 'services/api';
import { toast } from 'shared/toast';

import {
  Icon,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  HStack,
  Flex,
  Box,
  Skeleton,
} from '@chakra-ui/react';

import Loader from 'components/Loader';

import FragmentTableRow from './FragmentsTableRow';
import { FragmentResource, Row } from './types';

export type FragmentSummary = {
  total: number;
  material: number;
  material_percent: number;
  equipment: number;
  equipment_percent: number;
  labor: number;
  labor_percent: number;
  other: number;
  other_percent: number;
};

type QttySummary = {
  total_compositions: number;
  total_inputs: number;
};

type Props = {
  component_id: number;
  locale_key: string;
  price_type_id: number;
  price_type_key: string;
  isSubRow?: boolean;
  parentFragments?: FragmentResource[];
  parentQttySummary?: QttySummary;
};

const FragmentsTable: React.FC<Props> = ({
  isSubRow = false,
  parentFragments = [],
  component_id,
  locale_key,
  price_type_id,
  price_type_key,
}) => {
  const [fragments, setFragments] = useState<FragmentResource[]>([]);
  const [qttySummary, setQttySummary] = useState<QttySummary>(
    {} as QttySummary,
  );
  const [loading, setLoading] = useState(false);

  const getData = useCallback(async () => {
    setLoading(true);
    setFragments([]);

    try {
      const response = await api.get(
        `/composition/${component_id}/component/fragment`,
        {
          params: {
            page: 1,
            per_page: 999,
            'filter[locale_key]': locale_key,
            'filter[price_type_key]': price_type_key,
          },
        },
      );

      const fragmentsLoaded = response.data;

      const newQttySummary = {
        total_compositions: fragmentsLoaded.summary.total.total_compositions,
        total_inputs: fragmentsLoaded.summary.total.total_inputs,
      };

      setFragments(fragmentsLoaded.data);
      setQttySummary(newQttySummary);
    } catch (err) {
      setFragments([]);
      toast({
        description:
          err.response?.data?.message ||
          'Houve um erro ao carregar o mapeamento da composição.',
        status: 'error',
      });
      setQttySummary({} as QttySummary);
    } finally {
      setLoading(false);
    }
  }, [component_id, locale_key, price_type_key]);

  useEffect(() => {
    getData();
  }, [getData]);

  const isInput = (data: FragmentResource): boolean => {
    return data.fragment_type.includes('input');
  };

  const getType = (row: Row): string => {
    return row.original.fragment_type;
  };

  const getIcon = (row: Row): MdiReactIconComponentType => {
    if (getType(row).includes('input')) {
      return InfoIcon;
    }

    return GroupIcon;
  };

  const shouldShowInputIcon = (i: FragmentResource): boolean => {
    return ['M', 'E', 'L'].includes(i.fragment.classification.key);
  };

  type InputIcon = MdiReactIconComponentType | StyledIcon | undefined;
  const getInputIcon = useCallback((i: FragmentResource): InputIcon => {
    const value = i.fragment?.classification?.key;

    if (!value) {
      return undefined;
    }

    if (value === 'M') {
      return BricksIcon;
    }

    if (value === 'E') {
      return TrafficConeIcon;
    }

    if (value === 'L') {
      return ShovelIcon;
    }

    return undefined;
  }, []);

  const getInputIconType = useCallback((i: FragmentResource): string => {
    const value = i.fragment?.classification?.key;

    if (!value) {
      return '';
    }

    if (value === 'M') {
      return 'Material';
    }

    if (value === 'E') {
      return 'Equipamento';
    }

    if (value === 'L') {
      return 'Mão de obra';
    }

    return '';
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: () => 'Tipo',
        id: 'expander',
        Cell: ({ row }: { row: Row }) => {
          return (
            <HStack>
              <Box title={isInput(row.original) ? 'Insumo' : 'Composição'}>
                <Icon w={4} h={4} as={getIcon(row)} />
              </Box>

              {isInput(row.original) && shouldShowInputIcon(row.original) && (
                <Box title={getInputIconType(row.original)}>
                  <Icon w={4} h={4} as={getInputIcon(row.original)} />
                </Box>
              )}

              {getType(row).includes('composition') &&
                (row.isExpanded ? (
                  <Icon w={4} h={4} as={DownArrowIcon} />
                ) : (
                  <Icon w={4} h={4} as={RightArrowIcon} />
                ))}
            </HStack>
          );
        },
      },
      {
        Header: 'Código',
        accessor: 'data.code',
        width: '10%',
        Cell: ({ row }: { row: Row }) => {
          return `${row.original.fragment.code} / ${row.original.fragment.version.base.description}`;
        },
      },
      {
        Header: 'Descrição',
        accessor: 'data.description',
        width: '70%',
        Cell: ({ row }: { row: Row }) => {
          return (
            <Text
              maxWidth={{ base: '100%', md: '768px' }}
              whiteSpace="pre-wrap"
            >
              {row.original.fragment.description}
            </Text>
          );
        },
      },
      {
        Header: 'Unidade',
        accessor: 'data.unit_measure_id',
        width: '10%',
        Cell: ({ row }: { row: Row }) => {
          return row.original.fragment.unit_measure.description;
        },
      },
      {
        Header: 'Coeficiente',
        accessor: 'data.coefficient',
        isNumeric: true,
        width: '10%',
        Cell: ({ row }: { row: Row }) => {
          return row.original.coefficient;
        },
      },
      {
        Header: 'Valor unitário',
        accessor: 'data.unit_value',
        isNumeric: true,
        width: '10%',
        Cell: ({ row }: { row: Row }) => {
          const value = row.original.unit_price;

          return Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(value || 0);
        },
      },
      {
        Header: 'Total',
        accessor: 'data.total',
        isNumeric: true,
        width: '15%',
        Cell: ({ row }: { row: Row }) => {
          const value = row.original.total;

          return Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(value || 0);
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getInputIcon],
  );

  const getRowId = useCallback((row) => {
    return row.id;
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
  } = useTable(
    {
      // @ts-expect-error types
      columns,
      data: fragments,
      getRowId,
    },
    useExpanded,
  );

  const renderRowSubComponent = useCallback(
    ({ nextComponentId, nextComponentPriceTypeKey }) => (
      <FragmentsTable
        isSubRow
        parentFragments={fragments}
        component_id={nextComponentId}
        locale_key={locale_key}
        price_type_id={price_type_id}
        price_type_key={nextComponentPriceTypeKey}
      />
    ),
    [locale_key, price_type_id, fragments],
  );

  const parent = useMemo(() => {
    return parentFragments.find((f) => f.fragment.id === component_id);
  }, [parentFragments, component_id]);

  return (
    <>
      {isSubRow && (
        <Flex
          color="white"
          width="100%"
          flexDirection={{ base: 'column', lg: 'row' }}
          mb={8}
        >
          <>
            <Box
              my={{ base: 2, md: 0 }}
              display="flex"
              flexDirection="column"
              width="100%"
            >
              <Text fontWeight="600">Classe</Text>
              <Text fontSize="smaller" fontStyle="italic" mt={2}>
                {loading ? (
                  <Skeleton width={150} height={4} />
                ) : (
                  <>{parent?.fragment.type.classification.description || '-'}</>
                )}
              </Text>
            </Box>

            <Box
              my={{ base: 2, md: 0 }}
              display="flex"
              flexDirection="column"
              width="100%"
            >
              <Text fontWeight="600">Tipo</Text>
              <Text fontSize="smaller" fontStyle="italic" mt={2}>
                {loading ? (
                  <Skeleton width={150} height={4} />
                ) : (
                  <>{parent?.fragment.type.description || '-'}</>
                )}
              </Text>
            </Box>

            <Box
              my={{ base: 2, md: 0 }}
              display="flex"
              flexDirection="column"
              width="100%"
            >
              <Text fontWeight="600">Composições</Text>
              <Text fontSize="smaller" fontStyle="italic" mt={2}>
                {loading ? (
                  <Skeleton width={150} height={4} />
                ) : (
                  <>{qttySummary.total_compositions}</>
                )}
              </Text>
            </Box>

            <Box
              my={{ base: 2, md: 0 }}
              display="flex"
              flexDirection="column"
              width="100%"
            >
              <Text fontWeight="600">Insumos</Text>
              <Text fontSize="smaller" fontStyle="italic" mt={2}>
                {loading ? (
                  <Skeleton width={150} height={4} />
                ) : (
                  <> {qttySummary.total_inputs}</>
                )}
              </Text>
            </Box>
          </>
        </Flex>
      )}

      <TableContainer width="100%">
        <Table {...getTableProps()} borderBottom="none" variant="simple">
          <Thead>
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th
                    {...column.getHeaderProps()}
                    width={column.width}
                    // @ts-expect-error isNumeric does not exists on lib type
                    isNumeric={column.isNumeric}
                  >
                    {column.render('Header')}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>

          <Tbody {...getTableBodyProps()}>
            {!loading &&
              rows.length > 0 &&
              rows.map((row) => {
                prepareRow(row);

                return (
                  <FragmentTableRow
                    row={row as unknown as Row}
                    visibleColumns={visibleColumns}
                    renderRowSubComponent={renderRowSubComponent}
                    {...row.getRowProps()}
                  />
                );
              })}

            {loading && (
              <Tr>
                <Td
                  colSpan={visibleColumns.length}
                  borderBottom="none"
                  background="gray.100"
                >
                  <Loader />
                </Td>
              </Tr>
            )}

            {!loading && fragments.length === 0 && (
              <Tr>
                <Td
                  colSpan={visibleColumns.length}
                  borderBottom="none"
                  background="gray.100"
                  className="text-center"
                >
                  Nenhum resultado encontrado.
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};

export default FragmentsTable;
