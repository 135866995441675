export const typeMap = {
  single_family_residence: 'R1 (Residência Unifamiliar)',
  affordable_housing_pp: 'PP (Prédio Popular)',
  multi_family_residence_r8: 'R8 (Residência Multifamiliar)',
  multi_family_residence_r16: 'R16 (Residência Multifamiliar)',
  social_interest_project: 'PIS (Projeto de Interesse Social)',
  affordable_housing_rp1q: 'RP1Q (Residência Popular)',
  commercial_open_floors: 'CAL8 (Comercial Andares Livre)',
  commercial_offices_and_stores_csl8: 'CSL8 (Comercial Salas e Lojas)',
  commercial_offices_and_stores_csl16: 'CSL16 (Comercial Salas e Lojas)',
  industrial_warehouse: 'GI (Galpão Industrial)',
};

export const patternMap = {
  'single_family_residence:low': 'R1-B (Baixo)',
  'single_family_residence:normal': 'R1-N (Normal)',
  'single_family_residence:high': 'R1-A (Alto)',
  'affordable_housing_pp:low': 'PP-4B (Baixo)',
  'affordable_housing_pp:normal': 'PP-4N (Normal)',
  'multi_family_residence_r8:low': 'R8-B (Baixo)',
  'multi_family_residence_r8:normal': 'R8-N (Normal)',
  'multi_family_residence_r8:high': 'R8-A (Alto)',
  'multi_family_residence_r16:normal': 'R16-N (Normal)',
  'multi_family_residence_r16:high': 'R16-A (Alto)',
  social_interest_project: 'PIS (Projeto de Interesse Social)',
  affordable_housing_rp1q: 'RP1Q (Residência Popular)',
  'commercial_open_floors:normal': 'CAL8-N (Normal)',
  'commercial_open_floors:high': 'CAL8-A (Alto)',
  'commercial_offices_and_stores_csl8:normal': 'CSL8-N (Normal)',
  'commercial_offices_and_stores_csl8:high': 'CSL8-A (Alto)',
  'commercial_offices_and_stores_csl16:normal': 'CSL16-N (Normal)',
  'commercial_offices_and_stores_csl16:high': 'CSL16-A (Alto)',
  industrial_warehouse: 'GI (Galpão Industrial)',
};
