import React, { useCallback, useState } from 'react';

import { api } from 'services/api';
import { toast } from 'shared/toast';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  ModalFooter,
  Flex,
  Grid,
  FormControl,
  FormLabel,
  Heading,
  Textarea,
} from '@chakra-ui/react';

import { ModalRootProps } from 'components/Modal/Root';

import { Composition } from 'pages/Budget/Add/SubPages/Planning/Common/types';

type BudgetComponent = Composition;

interface ModalBudgetComponentObservationProps extends ModalRootProps {
  data: {
    budgetId: number;
    component: BudgetComponent;
  };
}

const ModalBudgetCompositionObservation: React.FC<ModalBudgetComponentObservationProps> =
  ({ onConfirm, handleClose, data: { budgetId, component }, ...restProps }) => {
    const [observation, setObservation] = useState(component.observation);
    const [loading, setLoading] = useState(false);

    const handleSubmitComponents = useCallback(async () => {
      setLoading(true);

      try {
        await api.put(`budget/${budgetId}/component/${component.id}`, {
          type: 'composition_original',
          component: {
            quantity: component.quantity,
            observation,
            composition_price_id: component.composition_price?.id,
          },
        });

        toast({
          description: 'Composição alterada com sucesso!',
          status: 'success',
        });

        if (onConfirm) onConfirm();
        handleClose();
      } catch (err) {
        toast({
          description:
            err.response?.data?.message ||
            'Houve um erro ao editar a composição.',
          status: 'error',
        });
      } finally {
        setLoading(false);
      }
    }, [budgetId, component, observation, handleClose, onConfirm]);

    return (
      <Modal {...restProps} size="2xl" scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Editar Composição</ModalHeader>

          <ModalCloseButton />

          <ModalBody>
            <Flex
              w="100%"
              as="form"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              sx={{
                '> *': {
                  marginY: 1,
                },
              }}
            >
              <Heading
                fontSize="medium"
                fontWeight="600"
                width="100%"
                textAlign="left"
                mt={-2}
                mb={4}
              >
                Componente do orçamento:{' '}
                {component.composition_price?.composition.description}
              </Heading>

              <Grid
                w="100%"
                templateColumns="repeat(auto-fit, minmax(300px, 1fr))"
                columnGap={2}
                rowGap={4}
              >
                <FormControl>
                  <FormLabel>Memória de cálculo</FormLabel>

                  <Textarea
                    value={observation}
                    onChange={(e) => setObservation(e.target.value)}
                    placeholder="Digite a memória de cálculo"
                    size="lg"
                    resize="none"
                  />
                </FormControl>
              </Grid>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button
              isLoading={loading}
              isDisabled={loading}
              colorScheme="green"
              onClick={handleSubmitComponents}
            >
              Confirmar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

export default ModalBudgetCompositionObservation;
