import React, { useCallback, useState } from 'react';

import { api } from 'services/api';
import { toast } from 'shared/toast';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';

import { ModalRootProps } from 'components/Modal/Root';

import Form, { FormData } from './Form';

type Component = {
  type: 'composition_custom';
  quantity: number;
  composition: Omit<FormData, 'quantity' | 'total_value'> & {
    immobilized: boolean;
  };
};

type ComponentData = {
  parent_stage_id: number;
  components: Component[];
};

type CustomComposition = {
  id: number;
  budget_composition_price: {
    id: number;
    code: string;
    description: string;
    observation: string;
    immobilized: boolean;
    unit_price: number;
    unit_measure_id: {
      id: number;
      description: string;
    };
  };
  type: 'separate';
  description: string;
  unit_measure_id: number;
  observation: string;
  immobilized: boolean;
  unit_price: number;
  labor: number;
  material: number;
  labor_percent: number;
  material_percent: number;
  equipment: number;
  refund: number;
  third_party: number;
  other: number;
  quantity: number;
  code: string;
};

interface ModalCompositionPrices extends ModalRootProps {
  data: {
    id: number;
    subStageId: number;
    budgetId: number;
    composition: CustomComposition;
    quantity: number;
  };
}

const ModalBudgetCustomCompositionAdd: React.FC<ModalCompositionPrices> = ({
  data: { id, subStageId, budgetId, quantity, composition },
  handleClose,
  onConfirm,
  mode,
  ...restProps
}) => {
  const [loading, setLoading] = useState(false);

  const getCustomComposition = useCallback(
    (original): FormData => {
      if (!composition) return {} as FormData;

      return {
        code: original.budget_composition_price.code,
        type: 'separate',
        description: original.budget_composition_price.description,
        unit_measure_id: original.budget_composition_price.unit_measure_id.id,
        unit_price: original.budget_composition_price.social_law_price,
        labor: original.labor,
        material: original.material,
        equipment: original.equipment,
        third_party: original.third_party,
        other: original.other,
        quantity,
        total_value: original.total,
      };
    },
    [quantity, composition],
  );

  const prepareComponents = useCallback(
    (data: FormData): ComponentData => {
      return {
        parent_stage_id: subStageId,
        components: [
          {
            type: 'composition_custom',
            quantity: data.quantity,
            composition: {
              code: data.code,
              type: 'separate',
              description: data.description,
              unit_measure_id: data.unit_measure_id,
              unit_price: data.unit_price || 0,
              labor: data.labor || 0,
              material: data.material || 0,
              equipment: data.equipment || 0,
              third_party: data.third_party || 0,
              other: data.other || 0,
              immobilized: false,
            },
          },
        ],
      };
    },
    [subStageId],
  );

  const prepareCustomComponent = useCallback(
    (data: FormData) => {
      return {
        id: composition.budget_composition_price.id,
        observation: composition.budget_composition_price.observation,
        labor_percent: composition.labor_percent,
        material_percent: composition.material_percent,
        type: 'separate',
        description: data.description,
        unit_measure_id: data.unit_measure_id,
        immobilized: false,
        unit_price: data.unit_price || 0,
        labor: data.labor || 0,
        material: data.material || 0,
        equipment: data.equipment || 0,
        refund: composition.refund,
        third_party: data.third_party || 0,
        other: data.other || 0,
        quantity: data.quantity,
        code: data.code,
      };
    },
    [composition],
  );

  const handleSubmitComponent = useCallback(
    async (data: FormData) => {
      setLoading(true);

      try {
        if (mode === 'add') {
          await api.post(`budget/${budgetId}/component`, {
            ...prepareComponents(data),
          });
        } else if (mode === 'edit') {
          await api.put(`budget/${budgetId}/component/${id}`, {
            type: 'composition_custom',
            component: prepareCustomComponent(data),
          });
        }

        toast({
          description: 'Item salvo com sucesso!',
          status: 'success',
        });

        if (onConfirm) onConfirm(data.quantity);
        handleClose();
      } catch (err) {
        toast({
          description:
            err?.response?.data?.message || 'Houve um erro ao salvar o item!',
          status: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [
      prepareComponents,
      budgetId,
      handleClose,
      onConfirm,
      mode,
      id,
      prepareCustomComponent,
    ],
  );

  return (
    <Modal {...restProps} size="6xl" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {mode === 'add'
            ? 'Adicionar serviço a preço informado'
            : 'Editar serviço a preço informado'}
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Form
            isSaving={loading}
            initialData={getCustomComposition(composition)}
            onSubmit={handleSubmitComponent}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalBudgetCustomCompositionAdd;
