import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import InputNumberFormat from 'react-number-format';

import { Check as CheckIcon } from '@styled-icons/boxicons-regular/Check';
import { api } from 'services/api';
import { toast } from 'shared/toast';

import {
  Button,
  Icon,
  Grid,
  FormControl,
  FormLabel,
  Flex,
  Input,
  FormErrorMessage,
  Heading,
} from '@chakra-ui/react';

import OwnSelect from 'components/OwnSelect';

import { UnitMeasure } from 'types/base';

export interface FormData {
  code: string;
  type: 'separate';
  description: string;
  unit_measure_id: number;
  observation?: string;
  unit_price: number;
  labor: number;
  material: number;
  equipment: number;
  third_party: number;
  other: number;
  quantity: number;
  total_value: number;
}

interface FormProps {
  onSubmit(data: FormData): void;
  initialData?: FormData;
  isSaving?: boolean;
}

const SearchFilter: React.FC<FormProps> = ({
  initialData,
  onSubmit,
  isSaving = false,
}) => {
  const [isLoadingFilters, setIsLoadingFilters] = useState(false);

  const {
    handleSubmit,
    setValue,
    control,
    register,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      ...initialData,
    },
  });

  const [quantity, setQuantity] = useState<number>(initialData?.quantity || 0);
  const [labor, setLabor] = useState<number>(initialData?.labor || 0);
  const [material, setMaterial] = useState<number>(initialData?.material || 0);
  const [equipment, setEquipment] = useState<number>(
    initialData?.equipment || 0,
  );
  const [third_party, setThirdPartyServices] = useState<number>(
    initialData?.third_party || 0,
  );
  const [other, setOther] = useState<number>(initialData?.other || 0);

  const [unitPrice, setUnitPrice] = useState<number>(
    initialData?.unit_price || 0,
  );
  const [totalValue, setTotalValue] = useState<number>(
    initialData?.total_value || 0,
  );

  const [unitMeasures, setUnitMeasures] = useState<UnitMeasure[]>([]);
  const [selectedUnitMeasure, setSelectedUnitMeasure] = useState<number | null>(
    initialData?.unit_measure_id || null,
  );

  const prepareSubmit = useCallback(
    (data) => {
      if (!unitPrice || unitPrice <= 0) {
        setError('unit_price', {
          type: 'required',
        });

        setError('total_value', {
          type: 'required',
        });

        return;
      }

      onSubmit({
        code: data.code,
        type: 'separate',
        description: data.description,
        unit_measure_id: data.unit_measure_id,
        unit_price: unitPrice || 0,
        labor: labor || 0,
        material: material || 0,
        equipment: equipment || 0,
        third_party: third_party || 0,
        other: other || 0,
        quantity: quantity || 0,
        total_value: totalValue || 0,
      });
    },
    [
      onSubmit,
      unitPrice,
      labor,
      material,
      equipment,
      third_party,
      other,
      quantity,
      totalValue,
      setError,
    ],
  );

  const getUnitMeasures = useCallback(async () => {
    setIsLoadingFilters(true);

    try {
      const response = await api.get('/unit/measure/list');
      const unitMeasuresLoaded = response.data;
      setUnitMeasures(unitMeasuresLoaded.data);
    } catch (err) {
      toast({
        description:
          err.response?.data?.message ||
          'Houve um erro ao carregar as unidades de medida!',
        status: 'error',
      });
    } finally {
      setIsLoadingFilters(false);
    }
  }, []);

  const unitMeasuresToSelect = useMemo(() => {
    return unitMeasures.map((unitMeasure) => ({
      value: String(unitMeasure.id),
      label: unitMeasure.description,
    }));
  }, [unitMeasures]);

  useEffect(() => {
    getUnitMeasures();
  }, [getUnitMeasures]);

  useEffect(() => {
    const unitPriceTotal = labor + material + equipment + third_party + other;

    setUnitPrice(unitPriceTotal);
    setValue('unit_price', unitPriceTotal);

    if (unitPriceTotal > 0) {
      clearErrors('unit_price');
    }
  }, [labor, material, equipment, third_party, other, setValue, clearErrors]);

  useEffect(() => {
    const total = unitPrice * quantity;

    setTotalValue(total);
    setValue('total_value', total);

    if (total > 0) {
      clearErrors('total_value');
    }
  }, [setValue, unitPrice, quantity, clearErrors]);

  return (
    <Flex
      w="100%"
      as="form"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      sx={{
        '> *': {
          marginY: 1,
        },
      }}
      onSubmit={handleSubmit(prepareSubmit)}
    >
      <Grid
        templateAreas={`"input input"`}
        gridTemplateColumns={{ base: '150px 1fr', md: '250px 1fr' }}
        columnGap={2}
        rowGap={2}
        marginBottom={4}
        width="100%"
      >
        <FormControl isInvalid={!!errors.code}>
          <FormLabel>Código</FormLabel>

          <Input {...register('code', { required: true })} />

          <FormErrorMessage>Código é obrigatória</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.description}>
          <FormLabel>Descrição</FormLabel>

          <Input {...register('description', { required: true })} />

          <FormErrorMessage>Descrição é obrigatória</FormErrorMessage>
        </FormControl>
      </Grid>

      <Grid
        w="100%"
        templateColumns="repeat(auto-fit, minmax(300px, 1fr))"
        columnGap={2}
        rowGap={4}
      >
        <FormControl isInvalid={!!errors.unit_measure_id}>
          <FormLabel>Unidade de medida</FormLabel>

          <Controller
            render={(field) => (
              <OwnSelect
                {...field}
                placeholder="Selecione"
                maxMenuHeight={200}
                options={unitMeasuresToSelect}
                value={unitMeasuresToSelect.filter(
                  (s) => Number(s.value) === selectedUnitMeasure,
                )}
                isInvalid={!!errors.unit_measure_id}
                isLoading={isLoadingFilters}
                isDisabled={unitMeasuresToSelect.length === 0}
                isClearable
                onChange={(selected) => {
                  const value = Number(selected?.value);

                  setSelectedUnitMeasure(value);
                  setValue('unit_measure_id', value);

                  if (value) {
                    clearErrors('unit_measure_id');
                  } else {
                    setError('unit_measure_id', {
                      type: 'required',
                    });
                  }
                }}
              />
            )}
            name="unit_measure_id"
            control={control}
            rules={{ required: true }}
          />

          <FormErrorMessage>Unidade de medida é obrigatória</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.quantity}>
          <FormLabel>Quantidade</FormLabel>

          <Controller
            render={({ field }) => (
              <InputNumberFormat
                {...field}
                type="tel"
                className="text-right"
                displayType="input"
                thousandSeparator="."
                decimalSeparator=","
                allowNegative={false}
                decimalScale={10}
                value={quantity || ''}
                onValueChange={(v) => {
                  setQuantity(v.floatValue || 0);
                  setValue('quantity', v.floatValue || 0);
                }}
                customInput={Input}
              />
            )}
            name="quantity"
            control={control}
            rules={{ required: true }}
          />

          <FormErrorMessage>Quantidade é obrigatória</FormErrorMessage>
        </FormControl>
      </Grid>

      <Heading size="xs" my="4" w="100%">
        Valores unitários (R$)
      </Heading>

      <Grid
        w="100%"
        templateColumns="repeat(auto-fit, minmax(200px, 1fr))"
        columnGap={2}
        rowGap={4}
        marginBottom={4}
      >
        <FormControl>
          <FormLabel>Mão de obra</FormLabel>

          <Controller
            render={({ field }) => (
              <InputNumberFormat
                {...field}
                type="tel"
                className="text-right"
                displayType="input"
                thousandSeparator="."
                decimalSeparator=","
                allowNegative={false}
                prefix="R$ "
                decimalScale={2}
                fixedDecimalScale
                value={labor || ''}
                onValueChange={(v) => {
                  setLabor(v.floatValue || 0);
                  setValue('labor', v.floatValue || 0);
                }}
                customInput={Input}
              />
            )}
            name="labor"
            control={control}
          />

          <FormErrorMessage>Mão de obra é obrigatória</FormErrorMessage>
        </FormControl>

        <FormControl>
          <FormLabel>Materiais</FormLabel>

          <Controller
            render={({ field }) => (
              <InputNumberFormat
                {...field}
                type="tel"
                className="text-right"
                displayType="input"
                thousandSeparator="."
                decimalSeparator=","
                allowNegative={false}
                prefix="R$ "
                decimalScale={2}
                fixedDecimalScale
                value={material || ''}
                onValueChange={(v) => {
                  setMaterial(v.floatValue || 0);
                  setValue('material', v.floatValue || 0);
                }}
                customInput={Input}
              />
            )}
            name="material"
            control={control}
          />

          <FormErrorMessage>Material é obrigatório</FormErrorMessage>
        </FormControl>

        <FormControl>
          <FormLabel>Equipamentos</FormLabel>

          <Controller
            render={({ field }) => (
              <InputNumberFormat
                {...field}
                type="tel"
                className="text-right"
                displayType="input"
                thousandSeparator="."
                decimalSeparator=","
                allowNegative={false}
                prefix="R$ "
                decimalScale={2}
                fixedDecimalScale
                value={equipment || ''}
                onValueChange={(v) => {
                  setEquipment(v.floatValue || 0);
                  setValue('equipment', v.floatValue || 0);
                }}
                customInput={Input}
              />
            )}
            name="equipment"
            control={control}
          />

          <FormErrorMessage>Equipamento é obrigatório</FormErrorMessage>
        </FormControl>

        <FormControl>
          <FormLabel>Serviços</FormLabel>

          <Controller
            render={({ field }) => (
              <InputNumberFormat
                {...field}
                type="tel"
                className="text-right"
                displayType="input"
                thousandSeparator="."
                decimalSeparator=","
                allowNegative={false}
                prefix="R$ "
                decimalScale={2}
                fixedDecimalScale
                value={third_party || ''}
                onValueChange={(v) => {
                  setThirdPartyServices(v.floatValue || 0);
                  setValue('third_party', v.floatValue || 0);
                }}
                customInput={Input}
              />
            )}
            name="third_party"
            control={control}
          />

          <FormErrorMessage>Serviço é obrigatório</FormErrorMessage>
        </FormControl>

        <FormControl>
          <FormLabel>Outros</FormLabel>

          <Controller
            render={({ field }) => (
              <InputNumberFormat
                {...field}
                type="tel"
                className="text-right"
                displayType="input"
                thousandSeparator="."
                decimalSeparator=","
                allowNegative={false}
                prefix="R$ "
                decimalScale={2}
                fixedDecimalScale
                value={other || ''}
                onValueChange={(v) => {
                  setOther(v.floatValue || 0);
                  setValue('other', v.floatValue || 0);
                }}
                customInput={Input}
              />
            )}
            name="other"
            control={control}
          />

          <FormErrorMessage>Outros é obrigatório</FormErrorMessage>
        </FormControl>
      </Grid>

      <Grid
        w="100%"
        templateColumns="repeat(auto-fit, minmax(300px, 1fr))"
        columnGap={2}
        rowGap={4}
      >
        <FormControl isInvalid={!!errors.unit_price} isReadOnly>
          <FormLabel>Preço unitário (R$)</FormLabel>

          <Controller
            render={() => (
              <InputNumberFormat
                type="tel"
                className="text-right"
                readOnly
                displayType="input"
                thousandSeparator="."
                decimalSeparator=","
                allowNegative={false}
                prefix="R$ "
                decimalScale={2}
                fixedDecimalScale
                value={unitPrice}
                customInput={Input}
              />
            )}
            name="unit_price"
            control={control}
            rules={{ required: true }}
          />

          <FormErrorMessage>Ao menos um valor é obrigatório</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.total_value}>
          <FormLabel>Total Geral (R$)</FormLabel>

          <Controller
            render={({ field }) => (
              <InputNumberFormat
                {...field}
                type="tel"
                className="text-right"
                readOnly
                displayType="input"
                thousandSeparator="."
                decimalSeparator=","
                allowNegative={false}
                prefix="R$ "
                decimalScale={2}
                fixedDecimalScale
                value={totalValue}
                customInput={Input}
              />
            )}
            name="total_value"
            control={control}
            rules={{ required: true }}
          />

          <FormErrorMessage>
            Valor total precisa ser maior que 0
          </FormErrorMessage>
        </FormControl>
      </Grid>

      <Button
        w="100%"
        type="submit"
        colorScheme="blue"
        isLoading={isSaving}
        leftIcon={<Icon as={CheckIcon} />}
        mt="4"
      >
        Salvar
      </Button>
    </Flex>
  );
};

export default SearchFilter;
